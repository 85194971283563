.resume {
  h2 {
    background-color: var(--block-background-color);
    border: var(--border);

    padding-left: calc(var(--spacing-unit) / 4);
    padding-right: calc(var(--spacing-unit) / 4);
    padding-top: calc(var(--spacing-unit) / 8);
    padding-bottom: calc(var(--spacing-unit) / 8);

    margin-left: calc(var(--spacing-unit) / -4);
    margin-right: calc(var(--spacing-unit) / -4);
    margin-top: calc(var(--spacing-unit));
    margin-bottom: calc(var(--spacing-unit));
  }

  h3 {
    clear: none;
  }
  
  h4 {
    clear: none;
  }

  ul, ol {
    padding-left: calc(var(--spacing-unit) / 2);
  }

  .competencies {
    text-align: center;

    ul {
      list-style: none;
      padding: 0;
      margin-left: 0;

      li {
        display: inline;

        &:not(:last-child):after {
          content: ' ￮ ';
        }
      }
    }
  }

  .experience {
    .company ~ .company h3 {
      margin-top: calc(var(--spacing-unit));
    }

    .company {
      margin-left: var(--spacing-unit);

      > .summary, h3 {
        font-weight: calc(var(--base-font-weight) * 1.15);
      }

      .outlined-text {
        margin-left: calc(var(--spacing-unit) * -0.85);
        padding-left: calc(var(--spacing-unit) * 0.85);
        border-left: var(--border);

        h4 {
          margin-top: calc(var(--spacing-unit) * 0.5);
        }
      }

      .description {
        margin-bottom: calc(var(--spacing-unit) * 0.5);
      }
    }

    .company:before {
      content: "◯";
      float: left;
      margin-left: calc(var(--spacing-unit) * -1.05);
      margin-top: calc(var(--spacing-unit) * 0.05);

      @include media-query($on-palm) {
        margin-top: calc(var(--spacing-unit) * 0.075);
      }   

      @include relative-font-size(0.8);
    }

    .position:before {
      content: "￮";
      float: left;
      margin-left: calc(var(--spacing-unit) * -1);
      margin-top: calc(var(--spacing-unit) * -0.025);
      @include relative-font-size(1.2);

      @include media-query($on-palm) {
        margin-top: calc(var(--spacing-unit) * -0.1);
      }
    }

    .duration {
      float: right;
      margin-left: 1em;
      margin-top: calc(var(--spacing-unit) * 0.15);
      @include heading-size(4);
    }
  }
}
