 /**
 * Site header
 */
.site-header {
  min-height: calc(var(--spacing-unit) * 1.865);

  // Positioning context for the mobile navigation icon
  position: relative;

  border-bottom: var(--border);
}

.site-title {
  @include relative-font-size(2.000);
  font-family: var(--base-font-family);
  font-variation-settings: "MONO" 0;
  font-weight: 450;
  margin-bottom: 0;
  float: left;

  @include media-query($on-palm) {
    @include relative-font-size(1.500);
  }
}

.site-nav {
  float: right;
  margin-top: calc(var(--base-line-height) * var(--base-font-size) / 1.5);

  @include media-query($on-laptop) {
    margin-top: calc(-1 * var(--base-line-height) * var(--base-font-size) / 2);
  }

  .nav-trigger {
      display: none;
  }

  .menu-icon {
    display: none;
  }

  .page-link {
    color: var(--text-color);
    line-height: var(--base-line-height);

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  .svg-icon {
    margin-left: -4px;
    position: relative;
    top: -1px;
  }

  @include media-query($on-laptop) {
    position: absolute;
    top: 9px;
    right: 0px;
    background-color: var(--background-color);
    border: var(--border);
    border-radius: 5px;
    text-align: right;
    z-index: 2;

    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;
    }

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg {
        fill: var(--text-color);
      }
    }

    input ~ .trigger {
      clear: both;
      display: none;
    }

    input:checked ~ .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }

    .svg-icon {
      padding: 2px 10px;
    }
  }
}



/**
 * Site footer
 */
.site-footer {
  border-top: var(--border);
  padding: var(--spacing-unit) 0;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: calc(var(--spacing-unit) / 2);
}

.contact-list {
  list-style: none;
}

.social-media-list {
  list-style: none;
  float: right;
  margin-left: var(--spacing-unit);
}

.social-media-list li {
  list-style-type: none;
  display: inline;
}

.profile-photo {
  float: left;
  margin-left: 0;
  padding-left: 0;
  margin-right: calc(var(--spacing-unit) / 2);
  border-radius: 50%;
  overflow: hidden;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: var(--text-color-light);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-col {
  margin-bottom: calc(var(--spacing-unit) / 2);
}

.footer-col-1 {
  min-width: var(--small-content-width);
  flex-basis: var(--small-content-width);
  flex-grow: 1;
  flex-shrink: 1;
  order: 1;
}

.footer-col-2 {
  min-width: var(--small-content-width);
  flex-basis: var(--small-content-width);
  flex-grow: 1;
  flex-shrink: 1;
  order: 3;
}

.footer-col-3 {
  order: 2;
  max-width: 50%;

  .p-note {
    display: none;
  }
}

.webring {
  text-align: center;
  white-space: nowrap;
}

.rss-subscribe {
  text-align: right;
}

@include media-query($on-laptop) {
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: 100%;
  }

  .footer-col-2 {
    order: 2;
  }

  .footer-col-3 {
    order: 3;
  }

  .social-media-list {
    float: none;
    margin-left: 0;
  }
}



/**
 * Page content
 */
.page-content {
  padding: var(--spacing-unit) 0;
  padding-bottom: calc(var(--spacing-unit) / 2);
  flex: 1;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li.h-article, > li.h-review {
    margin-top: calc(var(--spacing-unit) / 2);
    padding-bottom: var(--spacing-unit);
    border-bottom: var(--border-light);
  }

  > li.last.h-article, > li.last.h-review {
    border-bottom-style: none;
  }

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .post-list-sep {
    width: 100%;
  }

  .post-list-long-long-sep {
    text-align: center;
    color: var(--text-color-light);

    margin-bottom: calc(var(--spacing-unit) / 2);
  }

  .post-list-long-long-sep::before {
    content: "✼ ✼ ✼ "
  }

  .post-list-short-long-sep {
    border-top: var(--border-light);
    margin-top: calc(var(--spacing-unit) * 1.5);
  }

  .post-list-long-short-sep {
    border-bottom: var(--border-light);
//    margin-bottom: var(--spacing-unit);
  }
}

.short-post-group {
  min-width: calc(var(--small-content-width) + var(--spacing-unit) * 2);
  flex-basis: var(--small-content-width);
  flex-grow: 1;

  margin-left: calc(var(--spacing-unit) / 4);
  margin-right: calc(var(--spacing-unit) / 4);

  @include media-query($on-palm) {
    margin-right: 0px;
    margin-left: 0px;
  }
}

.post-sublist {
  margin-left: 0;
  list-style: none;

  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  margin-top: calc(var(--spacing-unit) / 2);
}

.page-pinned {
  .post-sublist {
    margin-top: 0;
  }
}

.post-list {
  .short-post {
    flex-grow: 1;

    min-width: var(--small-content-width);
    padding: calc(var(--spacing-unit) / 2);

    @include relative-font-size(0.8);
    font-variation-settings: "CASL" 1;
    text-align: left;
    background-color: var(--block-background-color);
    border: var(--border);
    box-shadow: 5px 5px var(--block-background-color);

    margin-top: calc(var(--spacing-unit) / 2);
  }

  .post-number-1 { background-color: var(--background-color-pride-one); }
  .post-number-2 { background-color: var(--background-color-pride-two); }
  .post-number-3 { background-color: var(--background-color-pride-three); }
  .post-number-4 { background-color: var(--background-color-pride-four); }
  .post-number-5 { background-color: var(--background-color-pride-five); }
  .post-number-6 { background-color: var(--background-color-pride-six); }
  .post-number-7 { background-color: var(--background-color-pride-seven); }
  .post-number-8 { background-color: var(--background-color-pride-eight); }

  .long-post {
    width: 100%;

    margin-top: calc(var(--spacing-unit) / 2);
    margin-bottom: calc(var(--spacing-unit) * 1);

    margin-left: calc(var(--spacing-unit) / 2);
    margin-right: calc(var(--spacing-unit) / 2);

    @include media-query($on-palm) {
      margin-right: 0px;
      margin-left: 0px;
    }
  }

  .long-post:nth-child(1) {
    margin-top: 0;
  }
}

.pinned .short-post {
  background-color: var(--pinned-background-color);
  border: var(--border-pinned);
}

.long-post-summary {
  @include relative-font-size(0.8);
  font-variation-settings: "CASL" 1;
  text-align: left;
  background-color: var(--block-background-color);
  border: var(--border);

  padding: calc(var(--spacing-unit) / 2);
//  margin-left: (calc(var(--spacing-unit) / 2 * -1));
//  margin-right: (calc(var(--spacing-unit) / 2 * -1));
  margin-bottom: calc(var(--spacing-unit) / 2);

  @include media-query($on-palm) {
    margin-right: 0px;
    margin-left: 0px;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.summary-text {
  display: inline;

  p:last-child {
    display: inline;
  }
}

.categories {
  display: inline;
}

.post-meta {
  @include relative-font-size(0.8);
  text-align: left;
  color: var(--text-color-light);
  margin-bottom: calc(var(--spacing-unit) / 2);
  white-space: nowrap;

  .post-meta-wrapper {
    display: flex;

    .post-meta-url {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-left: 0.5ex;
    }

    .post-type, .post-reply-to {
      flex-shrink: 0;
      margin-left: 0.75ex;
    }

    .post-type:before {
      content: "• "
    }
  }
}

.post-link {
  display: block;
  @include heading-size(1);
  text-transform: uppercase;
  text-align: center;
}

.post-link::before {
  content: ">";

  @include media-query($on-palm) {
    content: "";
  }
}

.post-link::after {
  content: "<";

  @include media-query($on-palm) {
    content: "";
  }
}

.backlink {
  display: none;
}

// List formatting for post content

.post-content, .summary-text, .resume {
  ul, ol {
    overflow: hidden;
    padding-left: var(--spacing-unit);
    margin-left: 0;

    li {
      list-style-position: outside;
      padding-left: 0;
    }
  }
}

// Gotta negate the h-entry class since the blog feed uses list items as the container.
li:not(.h-entry) {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}

/**
 * Posts
 */
.post {
  display: flex;
  flex-direction: column;
  height: 100%;

  margin-left: calc(var(--spacing-unit) / 2);
  margin-right: calc(var(--spacing-unit) / 2);

  @include media-query($on-palm) {
    margin-right: 0px;
    margin-left: 0px;
  }
}

.post-header {
  text-align: left;
}

.post-header .u-author {
  display: none;
}

.post-title {
  @include heading-size(1);
  text-transform: uppercase;
  text-align: center;
}

.post-title::before {
  content: "- ";

  @include media-query($on-palm) {
    content: "";
  }
}

.post-title::after {
  content: " -";

  @include media-query($on-palm) {
    content: "";
  }
}

.post-content {
  h1 {
    @include heading-size(1);
    clear: both;
  }

  h2 {
    @include heading-size(2);
    clear: both;
  }

  h3 {
    @include heading-size(3);
    clear: both;
  }

  h4 {
    font-size: var(--base-font-size);
    font-weight: calc(var(--base-font-weight) * 1.50);
    clear: both;
  }

  .u-photo {
    float: left;

    max-width: 30vh;
    max-height: 50vh;

    margin-right: calc(var(--spacing-unit) / 2);
    margin-bottom: calc(var(--spacing-unit) / 2);

    @include media-query($on-laptop) {
      max-width: 30vh;
      max-height: 50vh;
    }

    @include media-query($on-palm) {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .u-photo-odd {
    float: right;

    margin-left: calc(var(--spacing-unit) / 2);
    margin-right: 0px;
  }
}

@mixin photo-group {
  display: grid;
  grid-gap: calc(var(--spacing-unit) / 8);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  aspect-ratio: 16 / 10;
  margin-top: calc(var(--spacing-unit) / 2);

  a {
    display: block;
    position: relative;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .u-photo {
    display: none;
  }
}

.post-list .photo-group {
  width: 100%;
}

.photo-group-1 {
  margin-top: calc(var(--spacing-unit) / 2);

  .photo-group-image-1 {
    a {
      background-image: none !important;
    }

    .u-photo {
      display: block;
      max-height: 50vh;
      width: auto;
      height: auto;

      @include media-query($on-laptop) {
        max-height: 100%;
      }
    }
  }
}

.photo-group-2 {
  @include photo-group;

  grid: auto / auto auto;
}

.photo-group-3 {
  @include photo-group;

  grid: auto auto / auto auto;

  .photo-group-image-1 {
    grid-row-start: 1;
    grid-row-end: 3;
  }
}

.photo-group-4 {
  @include photo-group;

  grid: auto auto / auto auto;
}

.photo-group-5 {
  @include photo-group;

  grid: auto auto / auto auto auto;

  .photo-group-image-1 {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;
  }
}

.photo-group-6 {
  @include photo-group;

  grid: auto auto auto / auto auto auto;
  aspect-ratio: 4 / 3;

  .photo-group-image-1 {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

.photo-group-7 {
  @include photo-group;

  grid: auto auto auto auto / auto auto auto auto;
  aspect-ratio: 4 / 3;

  .photo-group-image-1 {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .photo-group-image-2 {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 3;
    grid-column-end: 5;
  }

  .photo-group-image-5 {
    grid-row-start: 3;
    grid-row-end: 5;
    grid-column-start: 2;
    grid-column-end: 4;
  }
}

.photo-group-8 {
  @include photo-group;

  grid: auto auto auto auto / auto auto auto auto auto;
  aspect-ratio: 4 / 3;

  .photo-group-image-1 {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .photo-group-image-4 {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 4;
    grid-column-end: 6;
  }

  .photo-group-image-5 {
    grid-row-start: 3;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .photo-group-image-8 {
    grid-row-start: 3;
    grid-row-end: 5;
    grid-column-start: 4;
    grid-column-end: 6;
  }
}

.photo-group-12 {
  @include photo-group;

  grid: auto auto auto / auto auto auto auto;
}

.post-short-content {
  p:last-of-type {
    display: inline;
  }
}

.post-syndication-links {
  display: flex;
  flex-direction: row;
  float: right;
}

.post-short-content {
  p:last-of-type {
    display: inline;
  }
}

.post-syndication-links {
  display: flex;
  flex-direction: row;
  float: right;
}

.post-syndication-link {
  filter: brightness(140%);
  padding-left: 5px;
}

/*
 * Book read/review stuff.
 */

.book-summary {
  .p-name {
    font-variation-settings: "slnt" -10, "ital" 1;
  }

  .isbn {
    display: none;
  }
}

.image-and-text {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: calc(var(--spacing-unit) / 2);

  .text {
    flex: 1;
    margin-top: calc(var(--spacing-unit) / 2);
    margin-left: calc(var(--spacing-unit) / 2);

    @include media-query($on-palm) {
      margin-left: 0;
    }
  }

  .photo-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .u-photo {
    margin-top: calc(var(--spacing-unit) / 2);
  }
}

.u-photo {
  border: var(--border);
}

/**
 * Webmentions
 */

.reactions {
  h4 {
    font-size: var(--base-font-size);
    font-weight: calc(var(--base-font-weight) * 1.50);
    padding-top: calc(var(--spacing-unit) / 2);
  }

  border-top: var(--border-light);
  margin-top: calc(var(--spacing-unit) / 2);
}

.reactions:empty {
  display: none;
}

.webmentions--likes {
  .webmentions__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    list-style: none;
    font-size: calc(var(--base-font-size) * 0.85);
  }

  .webmention__author {
    margin-right: calc(var(--spacing-unit) / 8);
    margin-bottom: calc(var(--spacing-unit) / 8);
  }
}

.webmentions--posts {
  .webmentions__list {
    list-style: none;
    font-size: calc(var(--base-font-size) * 0.85);
  }

  .webmention__author {
    margin-right: calc(var(--spacing-unit) / 4);
  }
}

.webmention article {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.webmention__author {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  vertical-align: middle;
}

.webmention__content {
  flex: 2;
  text-align: left;
  margin-right: calc(var(--spacing-unit) / 2);

  .p-category {
    display: none;
  }

  br {
    display: none;
  }
}

.webmention__meta {
  text-align: right;
}

.webmentions__not-found {
  display: none;
}

.webmention__author .p-name {
  display: none;
}

/**
 * The rest
 */

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: var(--spacing-unit);
  padding-top: calc(var(--spacing-unit) / 2);
  border-top: var(--border-light);

  @include media-query($on-palm) {
    @include relative-font-size(0.75);
  }
}

.paginate-control {
  flex: 1;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.home {
  .paginate-previous {
    text-align: left;
  }
  .paginate-next {
    text-align: right;
  }
}

.pinned {
  padding-bottom: var(--spacing-unit);
}

// Don't love this hack but...
.page-pinned {
  padding-bottom: calc(var(--spacing-unit) * 0.25);
}

.post {
  .paginate-previous {
    text-align: right;
    margin-left: calc(var(--spacing-unit) * 0.75);
  }
  .paginate-next {
    text-align: left;
    margin-right: calc(var(--spacing-unit) * 0.75);
  }
}

.footnotes {
  margin-top: calc(var(--spacing-unit) * 2);
  padding-top: calc(var(--spacing-unit) / 2);
  border-top: var(--border-light);
  font-weight: calc(var(--base-font-weight) * 1.15);
}

.footnotes ol {
  font-weight: 350;
  font-size: calc(var(--base-font-size) * 0.85);
  margin-top: calc(var(--spacing-unit) / 2);
}

.footnotes::before {
  content: "Footnotes:"
}

/***
 * Page archives
 */

.archive-groups {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  @include media-query($on-palm) {
    justify-content: space-between;
  }
}

/**
 * Graphviz renderings
 */

.graphviz-wrapper {
  ellipse, path, polygon {
    stroke: var(--text-color);
  }

  text {
    fill: var(--text-color);
    font-family: var(--base-font-family);
    font-variation-settings: "MONO" 0;
    font-size: calc(var(--base-font-size) * 0.75);
  }
}

/*
 * Mastodon follow button
 */

.mastodon {
  text-align: center;

  a {
    display: inline-block;
    color: var(--background-color);
    text-decoration: none;
    font-weight: 500;
    background: var(--icon-color);
    border-radius: 4px;
    padding: 0 8px 0 8px;

    .svg-icon {
      color: var(--background-color);
      vertical-align: middle;

      padding: 8px 0 10px 0;
      position: relative;
    }
  }
}
