/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}



/**
 * Basic styling
 */
body {
  font: var(--base-font-weight) var(--base-font-size)/var(--base-line-height) var(--base-font-family);
  font-variation-settings: "MONO" 0;
  color: var(--text-color);
  background-color: var(--background-color);
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
     -moz-font-feature-settings: "kern" 1;
       -o-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  font-kerning: normal;
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  margin-top: var(--spacing-unit);

  margin-left: 18vw;
  margin-right: 18vw;

  text-align: justify;
  hyphens: auto;
  -webkit-hyphens: auto;
  word-spacing: -0.05em;

  @include media-query($on-laptop) {
    margin-left: var(--spacing-unit) !important;
    margin-right: var(--spacing-unit) !important;
  }

  @include media-query($on-palm) {
    margin-left: calc(var(--spacing-unit) / 2) !important;
    margin-right: calc(var(--spacing-unit) / 2) !important;
  }
}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: calc(var(--spacing-unit) / 2);
}



/**
 * `main` element
 */
main {
  display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
}

/**
 * General formatting
 */

em {
  font-variation-settings: "MONO" 0, "slnt" -10, "ital" 1;
}

hr {
  border: none;
  border-top: var(--border);
  margin-top: var(--spacing-unit);
  margin-bottom: var(--spacing-unit);
  margin-left: 10%;
  margin-right: 10%;
}

/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}

.graphviz-wrapper {
  svg {
    max-width: 100%;
    height: 100%;
  }
}

/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  @include relative-font-size(0.8);
}

/**
 * Definition list
 */

dl {
  display: grid;
  grid-template-columns: auto 1fr;
}

dt {
  text-align: right;
  grid-column-start: 1;
  grid-column-end: 2;
}

dd {
  padding-left: 1em;
  grid-column-start: 2;
  grid-column-end: 3;
}

/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
  font-weight: var(--base-font-weight);
  text-align: left;
  @include relative-font-size(1.5);

  @include media-query($on-palm) {
    @include relative-font-size(1.25);
  }
}



/**
 * Links
 */
a {
  color: var(--link-color);
  text-decoration: var(--link-decoration, none);

  &:visited {
    color: var(--link-visited-color);
  }

  &:hover {
    color: var(--text-color);
    text-decoration: var(--link-hover-decoration, underline);
  }

  .social-media-list &:hover {
    text-decoration: none;

    .username {
      text-decoration: var(--link-hover-decoration, underline);
    }
  }
}


/**
 * Blockquotes
 */
blockquote {
  color: var(--text-color-light);
  border-left: var(--border-heavy);
  padding-left: calc(var(--spacing-unit) / 2);
  font-variation-settings: "slnt" -10, "CASL" 0.5;
  font-weight: calc(var(--base-font-weight) * 1.15);

  > :last-child {
    margin-bottom: 0;
  }
}



/**
 * Code formatting
 */

li.long-post div.highlight {
  width: calc(var(--content-width) - var(--spacing-unit));
}

li.short-post div.highlight {
  width: 100%;
}

pre,
code {
  border: var(--border);
  border-radius: 3px;
  background-color: var(--block-background-color);
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}



/**
 * Wrapper
 */
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: var(--content-width);
  margin-right: auto;
  margin-left: auto;
  @extend %clearfix;
}



/**
 * Clearfix
 */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}



/**
 * Icons
 */

.svg-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    fill: var(--icon-color);
    vertical-align: middle;
    color: var(--icon-color);
}

.social-media-list {
  li + li {
    padding-top: 5px;
  }

  .svg-icon {
    padding-right: 0.15em;
    width: 1.25em;
    height: 1.25em;
  }
}



/**
 * Tables
 */
table {
  margin-bottom: var(--spacing-unit);
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  border: var(--border);
  tr {
    &:nth-child(even) {
      background-color: var(--block-background-color);
    }
  }
  th, td {
    padding: calc(var(--spacing-unit) / 3) (calc(var(--spacing-unit) / 2));
  }
  th {
    background-color: var(--block-background-color-heavy);
    border: var(--border);
    width: 0;
    white-space: nowrap;
    vertical-align: top;
  }
  td {
    border: var(--border);
  }
}

/**
 * Knitting patterns
 */

.knitting {
  font-family: StitchMastery
}
