@charset "utf-8";

// Define defaults for each variable.

@font-face {
  font-family: 'Recursive';
  src: url("/assets/fonts/recursive-MONO_CASL_wght_slnt_ital--2019_11_05-00_13.woff2");
  font-weight: 300 900
}

@font-face {
  font-family: 'StitchMastery';
  src: url("/assets/fonts/StitchMasteryDash.ttf")
}

@font-face {
  font-family: 'PxPlusVGA9';
  src: url("/assets/fonts/PxPlus_IBM_VGA9.ttf");
}

@font-face {
  font-family: 'PxPlusCGA';
  src: url("/assets/fonts/PxPlus_IBM_CGA.ttf");
}

$on-palm:          600px !default;
$on-laptop:        1300px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: calc(var(--base-font-size) * #{$ratio});
}

@mixin heading-size($level) {
  @include relative-font-size(1.90 - $level * 0.30);

  @include media-query($on-laptop) {
    @include relative-font-size(1.90 - $level * 0.30);
  }
}

// Now take our Sass variables and convert them to our CSS variables that we'll
// use in our derived stylesheets.

:root {
  $text-font-family: Recursive !default;
  $text-color:       #010101 !default;
  $text-color-light: #828282 !default;
  $background-color: #fdfdfd !default;
  $pinned-color:     #fffaf0 !default;
  $brand-color:      rgb(0, 0, 250) !default;

  @import "theme-light";
  @import "minima/syntax-lucius-light";
}

[data-theme="dark"] {
  $text-font-family: Recursive !default;
  $text-color:       #e0e0e0 !default;
  $text-color-light: #828282 !default;
  $background-color: #1f1f1f !default;
  $pinned-color:     #242e35 !default;
  $brand-color:      #80d0f0 !default;

  @import "theme-dark";
  @import "minima/syntax-lucius-dark";

  .dark-mode-toggle {
    .svg-icon {
      fill: #f0d922;
    }
  }

  .p-rating {
    color: #f0d922;
  }
}

[data-theme="retro-green"] {
  $text-font-family: PxPlusVGA9 !default;
  $brand-color:      #12ff89 !default;
  $text-color:       darken($brand-color, 10%) !default;
  $text-color-light: lighten($brand-color, 10%) !default;
  $pinned-color:     #0c512d !default;
  $background-color: #08361e !default;

  @import "theme-monochrome";
  @import "minima/syntax-monochrome";

  .svg-icon {
    transform: scale(0.8, 0.8);
  }
}

[data-theme="retro-amber"] {
  $text-font-family: PxPlusCGA !default;
  $brand-color:      #ffa800 !default;
  $text-color:       darken($brand-color, 5%) !default;
  $text-color-light: lighten($brand-color, 15%) !default;
  $pinned-color:     #523906 !default;
  $background-color: #362504 !default;

  @import "theme-monochrome";
  @import "minima/syntax-monochrome";

  * {
    --base-font-size: 10px;
  }

  .svg-icon {
    transform: scale(0.8, 0.8);
  }
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting",
  "minima/resume"
;
