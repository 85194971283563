* {
  --spacing-unit: 30px;

  /* The reason you have this set to 45vw, even though it looks a bit cramped
     on your laptop, is because on a typically 16:9 monitor, moving to 55vw
     is too damn wide. 

     If you want to do this properly, you need an additional media query for
     3:2 displays like the Framework. */
  --content-width: 45vw;
  --small-content-width: 10vw;

  @include media-query($on-laptop) {
    --content-width: 65vw;
    --small-content-width: 20vw;
  }

  @include media-query($on-palm) {
    --content-width: 100vw;
    --small-content-width: 50vw;
  }

  --base-font-family: #{$text-font-family};
  --base-font-size: 16px;
  --base-font-weight: 350;
  --base-line-height: 1.5;

  --text-color: #{$text-color};
  --text-color-light: #{$text-color-light};
  --icon-color: #{$text-color-light};
  --background-color: #{$background-color};

  --block-background-color: #{darken($background-color, 3%)};
  --block-background-color-heavy: #{darken($background-color, 6%)};
  --pinned-background-color: #{$pinned-color};

  --border: 1px solid #{lighten($text-color-light, 30%)};
  --border-heavy: 1px solid #{$text-color-light};
  --border-light: 1px solid #{lighten($text-color-light, 40%)};
  --border-pinned: 1px dashed #{lighten($text-color-light, 30%)};

  --link-color: #{$brand-color};
  --link-visited-color: #{darken($brand-color, 15%)};

  --background-color-pride-one: #{mix($background-color, #E50000, 90%)};
  --background-color-pride-two: #{mix($background-color, #FF8D00, 90%)};
  --background-color-pride-three: #{mix($background-color, #FFEE00, 90%)};
  --background-color-pride-four: #{mix($background-color, #028121, 90%)};
  --background-color-pride-five: #{mix($background-color, #004CFF, 90%)};
  --background-color-pride-six: #{mix($background-color, #760088, 90%)};
  --background-color-pride-seven: #{mix($background-color, #FFAFC7, 90%)};
  --background-color-pride-eight: #{mix($background-color, #73D7EE, 90%)};
}
