@import "theme-light";

* {
  --block-background-color: #{lighten($background-color, 5%)};
  --block-background-color-heavy: #{lighten($background-color, 8%)};

  --border: 1px solid #{$text-color-light};
  --border-light: 1px solid #{darken($text-color-light, 20%)};
  --border-pinned: 1px dashed #{$text-color-light};
}
