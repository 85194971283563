/**
 * Syntax highlighting styles
 */
code {
  font: var(--highlight-font, var(--base-font-family));
  font-size: 93%;
  font-variation-settings: var(--highlight-font-variation-settings, "MONO" 1)
}

.highlight {
  background: var(--block-background-color);
  @extend %vertical-rhythm;

  .highlighter-rouge & {
    background: var(--block-background-color);
  }

  .c {
    font-style: var(--comment-font-style, italic);
    color: var(--comment-color, #998);
    font-weight: var(--comment-font-weight, var(--base-font-weight));
    background-color: var(--comment-background-color, var(--block-background-color));
  }

  .err {
    background-color: var(--error-background-color, #e3d2d2);
    font-weight: var(--error-font-weight, var(--base-font-weight));
    color: var(--error-color, #a61717);
    font-style: var(--error-font-style, normal);
  }

  .k {
    font-style: var(--keyword-font-style, normal);
    color: var(--keyword-color, var(--text-color));
    font-weight: var(--keyword-font-weight, bold);
    background-color: var(--keyword-background-color, var(--block-background-color));
  }

  .o {
    font-style: var(--operator-font-style, normal);
    color: var(--operator-color, var(--text-color));
    font-weight: var(--operator-font-weight, bold);
    background-color: var(--operator-background-color, var(--block-background-color));
  }

  .cm {
    background-color: var(--comment-multiline-background-color, var(--block-background-color));
    font-weight: var(--comment-multiline-font-weight, var(--base-font-weight));
    color: var(--comment-multiline-color, #998);
    font-style: var(--comment-multiline-font-style, italic);
  }

  .cp {
    font-style: var(--comment-preproc-font-style, normal);
    color: var(--comment-preproc-color, #999);
    font-weight: var(--comment-preproc-font-weight, bold);
    background-color: var(--comment-preproc-background-color, var(--block-background-color));
  }

  .c1 {
    font-weight: var(--comment-single-font-weight, var(--base-font-weight));
    background-color: var(--comment-single-background-color, var(--block-background-color));
    font-style: var(--comment-single-font-style, italic);
    color: var(--comment-single-color, #998);
  }

  .cs {
    color: var(--comment-special-color, #999);
    font-style: var(--comment-special-font-style, italic);
    background-color: var(--comment-special-background-color, var(--block-background-color));
    font-weight: var(--comment-special-font-weight, bold);
  }

  .gd {
    background-color: var(--generic-deleted-background-color, #fdd);
    font-weight: var(--generic-deleted-font-weight, var(--base-font-weight));
    color: var(--generic-deleted-color, #000);
    font-style: var(--generic-deleted-font-style, normal);
  }

  .gd .x {
    background-color: var(--generic-deleted-specific-background-color, #faa);
    font-weight: var(--generic-deleted-specific-font-weight, var(--base-font-weight));
    color: var(--generic-deleted-specific-color, #000);
    font-style: var(--generic-deleted-specific-font-style, normal);
  }

  .ge {
    font-weight: var(--generic-emph-font-weight, var(--base-font-weight));
    background-color: var(--generic-emph-background-color, var(--block-background-color));
    font-style: var(--generic-emph-font-style, italic);
    color: var(--generic-emph-color, var(--text-color));
  }

  .gr {
    font-style: var(--generic-error-font-style, normal);
    color: var(--generic-error-color, #a00);
    font-weight: var(--generic-error-font-weight, var(--base-font-weight));
    background-color: var(--generic-error-background-color, var(--block-background-color));
  }

  .gh {
    font-style: var(--generic-heading-font-style, normal);
    color: var(--generic-heading-color, #999);
    font-weight: var(--generic-heading-font-weight, var(--base-font-weight));
    background-color: var(--generic-heading-background-color, var(--block-background-color));
  }

  .gi {
    color: var(--generic-inserted-color, #000);
    font-style: var(--generic-inserted-font-style, normal);
    background-color: var(--generic-inserted-background-color, #dfd);
    font-weight: var(--generic-inserted-font-weight, var(--base-font-weight));
  }

  .gi .x {
    font-weight: var(--generic-inserted-specific-font-weight, var(--base-font-weight));
    background-color: var(--generic-inserted-specific-background-color, #afa);
    font-style: var(--generic-inserted-specific-font-style, normal);
    color: var(--generic-inserted-specific-color, #000);
  }

  .go {
    color: var(--generic-output-color, #888);
    font-style: var(--generic-output-font-style, normal);
    background-color: var(--generic-output-background-color, var(--block-background-color));
    font-weight: var(--generic-output-font-weight, var(--base-font-weight));
  }

  .gp {
    background-color: var(--generic-prompt-background-color, var(--block-background-color));
    font-weight: var(--generic-prompt-font-weight, var(--base-font-weight));
    color: var(--generic-prompt-color, #555);
    font-style: var(--generic-prompt-font-style, normal);
  }

  .gs {
    background-color: var(--generic-strong-background-color, var(--block-background-color));
    font-weight: var(--generic-strong-font-weight, bold);
    color: var(--generic-strong-color, var(--text-color));
    font-style: var(--generic-strong-font-style, normal);
  }

  .gu {
    background-color: var(--generic-subheading-background-color, var(--block-background-color));
    font-weight: var(--generic-subheading-font-weight, var(--base-font-weight));
    color: var(--generic-subheading-color, #aaa);
    font-style: var(--generic-subheading-font-style, normal);
  }

  .gt {
    color: var(--generic-traceback-color, #a00);
    font-style: var(--generic-traceback-font-style, normal);
    background-color: var(--generic-traceback-background-color, var(--block-background-color));
    font-weight: var(--generic-traceback-font-weight, var(--base-font-weight));
  }

  .kc {
    font-style: var(--keyword-constant-font-style, normal);
    color: var(--keyword-constant-color, var(--text-color));
    font-weight: var(--keyword-constant-font-weight, bold);
    background-color: var(--keyword-constant-background-color, var(--block-background-color));
  }

  .kd {
    background-color: var(--keyword-declaration-background-color, var(--block-background-color));
    font-weight: var(--keyword-declaration-font-weight, bold);
    color: var(--keyword-declaration-color, var(--text-color));
    font-style: var(--keyword-declaration-font-style, normal);
  }

  .kp {
    color: var(--keyword-pseudo-color, var(--text-color));
    font-style: var(--keyword-pseudo-font-style, normal);
    background-color: var(--keyword-pseudo-background-color, var(--block-background-color));
    font-weight: var(--keyword-pseudo-font-weight, bold);
  }

  .kr {
    background-color: var(--keyword-reserved-background-color, var(--block-background-color));
    font-weight: var(--keyword-reserved-font-weight, bold);
    color: var(--keyword-reserved-color, var(--text-color));
    font-style: var(--keyword-reserved-font-style, normal);
  }

  .kt {
    font-weight: var(--keyword-type-font-weight, bold);
    background-color: var(--keyword-type-background-color, var(--block-background-color));
    font-style: var(--keyword-type-font-style, normal);
    color: var(--keyword-type-color, #458);
  }

  .m {
    font-style: var(--literal-number-font-style, normal);
    color: var(--literal-number-color, #099);
    font-weight: var(--literal-number-font-weight, var(--base-font-weight));
    background-color: var(--literal-number-background-color, var(--block-background-color));
  }

  .s {
    color: var(--literal-string-color, #d14);
    font-style: var(--literal-string-font-style, normal);
    background-color: var(--literal-string-background-color, var(--block-background-color));
    font-weight: var(--literal-string-font-weight, var(--base-font-weight));
  }

  .n {
    font-weight: var(--name-font-weight, var(--base-font-weight));
    background-color: var(--name-background-color, var(--block-background-color));
    font-style: var(--name-font-style, normal);
    color: var(--name-color, var(--text-color));
  }

  .na {
    font-weight: var(--name-attribute-font-weight, var(--base-font-weight));
    background-color: var(--name-attribute-background-color, var(--block-background-color));
    font-style: var(--name-attribute-font-style, normal);
    color: var(--name-attribute-color, #008080);
  }

  .nb {
    background-color: var(--name-builtin-background-color, var(--block-background-color));
    font-weight: var(--name-builtin-font-weight, var(--base-font-weight));
    color: var(--name-builtin-color, #0086B3);
    font-style: var(--name-builtin-font-style, normal);
  }

  .nc {
    color: var(--name-class-color, #458);
    font-style: var(--name-class-font-style, normal);
    background-color: var(--name-class-background-color, var(--block-background-color));
    font-weight: var(--name-class-font-weight, bold);
  }

  .no {
    color: var(--name-constant-color, #008080);
    font-style: var(--name-constant-font-style, normal);
    background-color: var(--name-constant-background-color, var(--block-background-color));
    font-weight: var(--name-constant-font-weight, var(--base-font-weight));
  }

  .ni {
    color: var(--name-entity-color, #800080);
    font-style: var(--name-entity-font-style, normal);
    background-color: var(--name-entity-background-color, var(--block-background-color));
    font-weight: var(--name-entity-font-weight, var(--base-font-weight));
  }

  .ne {
    font-weight: var(--name-exception-font-weight, bold);
    background-color: var(--name-exception-background-color, var(--block-background-color));
    font-style: var(--name-exception-font-style, normal);
    color: var(--name-exception-color, #900);
  }

  .nf {
    font-style: var(--name-function-font-style, normal);
    color: var(--name-function-color, #900);
    font-weight: var(--name-function-font-weight, bold);
    background-color: var(--name-function-background-color, var(--block-background-color));
  }

  .nn {
    color: var(--name-namespace-color, #555);
    font-style: var(--name-namespace-font-style, normal);
    background-color: var(--name-namespace-background-color, var(--block-background-color));
    font-weight: var(--name-namespace-font-weight, var(--base-font-weight));
  }

  .nt {
    font-weight: var(--name-tag-font-weight, var(--base-font-weight));
    background-color: var(--name-tag-background-color, var(--block-background-color));
    font-style: var(--name-tag-font-style, normal);
    color: var(--name-tag-color, #000080);
  }

  .nv {
    font-style: var(--name-variable-font-style, normal);
    color: var(--name-variable-color, #008080);
    font-weight: var(--name-variable-font-weight, var(--base-font-weight));
    background-color: var(--name-variable-background-color, var(--block-background-color));
  }

  .ow {
    font-style: var(--operator-word-font-style, normal);
    color: var(--operator-word-color, var(--text-color));
    font-weight: var(--operator-word-font-weight, bold);
    background-color: var(--operator-word-background-color, var(--block-background-color));
  }

  .w {
    background-color: var(--text-whitespace-background-color, var(--block-background-color));
    font-weight: var(--text-whitespace-font-weight, var(--base-font-weight));
    color: var(--text-whitespace-color, #bbb);
    font-style: var(--text-whitespace-font-style, normal);
  }

  .mf {
    font-style: var(--literal-number-float-font-style, normal);
    color: var(--literal-number-float-color, #099);
    font-weight: var(--literal-number-float-font-weight, var(--base-font-weight));
    background-color: var(--literal-number-float-background-color, var(--block-background-color));
  }

  .mh {
    color: var(--literal-number-hex-color, #099);
    font-style: var(--literal-number-hex-font-style, normal);
    background-color: var(--literal-number-hex-background-color, var(--block-background-color));
    font-weight: var(--literal-number-hex-font-weight, var(--base-font-weight));
  }

  .mi {
    background-color: var(--literal-number-integer-background-color, var(--block-background-color));
    font-weight: var(--literal-number-integer-font-weight, var(--base-font-weight));
    color: var(--literal-number-integer-color, #099);
    font-style: var(--literal-number-integer-font-style, normal);
  }

  .mo {
    background-color: var(--literal-number-oct-background-color, var(--block-background-color));
    font-weight: var(--literal-number-oct-font-weight, var(--base-font-weight));
    color: var(--literal-number-oct-color, #099);
    font-style: var(--literal-number-oct-font-style, normal);
  }

  .sb {
    background-color: var(--literal-string-backtick-background-color, var(--block-background-color));
    font-weight: var(--literal-string-backtick-font-weight, var(--base-font-weight));
    color: var(--literal-string-backtick-color, #d14);
    font-style: var(--literal-string-backtick-font-style, normal);
  }

  .sc {
    font-style: var(--literal-string-char-font-style, normal);
    color: var(--literal-string-char-color, #d14);
    font-weight: var(--literal-string-char-font-weight, var(--base-font-weight));
    background-color: var(--literal-string-char-background-color, var(--block-background-color));
  }

  .sd {
    font-weight: var(--literal-string-doc-font-weight, var(--base-font-weight));
    background-color: var(--literal-string-doc-background-color, var(--block-background-color));
    font-style: var(--literal-string-doc-font-style, normal);
    color: var(--literal-string-doc-color, #d14);
  }

  .s2 {
    font-style: var(--literal-string-double-font-style, normal);
    color: var(--literal-string-double-color, #d14);
    font-weight: var(--literal-string-double-font-weight, var(--base-font-weight));
    background-color: var(--literal-string-double-background-color, var(--block-background-color));
  }

  .se {
    font-weight: var(--literal-string-escape-font-weight, var(--base-font-weight));
    background-color: var(--literal-string-escape-background-color, var(--block-background-color));
    font-style: var(--literal-string-escape-font-style, normal);
    color: var(--literal-string-escape-color, #d14);
  }

  .sh {
    color: var(--literal-string-heredoc-color, #d14);
    font-style: var(--literal-string-heredoc-font-style, normal);
    background-color: var(--literal-string-heredoc-background-color, var(--block-background-color));
    font-weight: var(--literal-string-heredoc-font-weight, var(--base-font-weight));
  }

  .si {
    background-color: var(--literal-string-interpol-background-color, var(--block-background-color));
    font-weight: var(--literal-string-interpol-font-weight, var(--base-font-weight));
    color: var(--literal-string-interpol-color, #d14);
    font-style: var(--literal-string-interpol-font-style, normal);
  }

  .sx {
    font-style: var(--literal-string-other-font-style, normal);
    color: var(--literal-string-other-color, #d14);
    font-weight: var(--literal-string-other-font-weight, var(--base-font-weight));
    background-color: var(--literal-string-other-background-color, var(--block-background-color));
  }

  .sr {
    color: var(--literal-string-regex-color, #009926);
    font-style: var(--literal-string-regex-font-style, normal);
    background-color: var(--literal-string-regex-background-color, var(--block-background-color));
    font-weight: var(--literal-string-regex-font-weight, var(--base-font-weight));
  }

  .s1 {
    background-color: var(--literal-string-single-background-color, var(--block-background-color));
    font-weight: var(--literal-string-single-font-weight, var(--base-font-weight));
    color: var(--literal-string-single-color, #d14);
    font-style: var(--literal-string-single-font-style, normal);
  }

  .ss {
    color: var(--literal-string-symbol-color, #990073);
    font-style: var(--literal-string-symbol-font-style, normal);
    background-color: var(--literal-string-symbol-background-color, var(--block-background-color));
    font-weight: var(--literal-string-symbol-font-weight, var(--base-font-weight));
  }

  .bp {
    font-weight: var(--name-builtin-pseudo-font-weight, var(--base-font-weight));
    background-color: var(--name-builtin-pseudo-background-color, var(--block-background-color));
    font-style: var(--name-builtin-pseudo-font-style, normal);
    color: var(--name-builtin-pseudo-color, #999);
  }

  .vc {
    color: var(--name-variable-class-color, #008080);
    font-style: var(--name-variable-class-font-style, normal);
    background-color: var(--name-variable-class-background-color, var(--block-background-color));
    font-weight: var(--name-variable-class-font-weight, var(--base-font-weight));
  }

  .vg {
    background-color: var(--name-variable-global-background-color, var(--block-background-color));
    font-weight: var(--name-variable-global-font-weight, var(--base-font-weight));
    color: var(--name-variable-global-color, #008080);
    font-style: var(--name-variable-global-font-style, normal);
  }

  .vi {
    font-style: var(--name-variable-instance-font-style, normal);
    color: var(--name-variable-instance-color, #008080);
    font-weight: var(--name-variable-instance-font-weight, var(--base-font-weight));
    background-color: var(--name-variable-instance-background-color, var(--block-background-color));
  }

  .il {
    font-weight: var(--literal-number-integer-long-font-weight, var(--base-font-weight));
    background-color: var(--literal-number-integer-long-background-color, var(--block-background-color));
    font-style: var(--literal-number-integer-long-font-style, normal);
    color: var(--literal-number-integer-long-color, #099);
  }

  .p {
    font-weight: var(--paren-weight, var(--base-font-weight));
    background-color: var(--paren-background-color, var(--block-background-color));
    font-style: var(--paren-style, normal);
    color: var(--paren-color, #099);
  }
}
