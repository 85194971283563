* {
  --keyword-color: #{$statement};
  --keyword-font-weight: normal;
  --keyword-reserved-color: #{$statement};
  --keyword-reserved-font-weight: normal;
  --operator-color: #{$statement};
  --operator-font-weight: normal;
  --comment-color: #{$comment};
  --error-color: #{$errorfg};
  --error-background-color: #{$errorbg};
  --generic-error-color: #{$errorfg};
  --generic-error-background-color: #{$errorbg};
  --comment-multi-line-color: #{$comment};
  --comment-preproc-color: #{$preproc};
  --comment-single-color: #{$comment};
  --comment-special-color: #{$comment};
  --generic-deleted-color: #{$text-color};
  --generic-deleted-background-color: #{$background-color};
  --generic-deleted-specific-color: #{$text-color};
  --generic-deleted-specific-background-color: #{$background-color};
  --generic-heading-color:  #{$title};
  --generic-inserted-color: #{$text-color};
  --generic-inserted-background-color: #{$background-color};
  --generic-inserted-specific-color: #{$text-color};
  --generic-inserted-specific-background-color: #{$background-color};
  --generic-output-color: #{$text-color};
  --generic-prompt-color: #{$special};
  --generic-subheading-color:  #{$title};
  --generic-traceback-color:  #{$text-color};
  --keyword-type-color: #{$type};
  --literal-number-color: #{$constant};
  --literal-string-color: #{$constant};
  --name-attribute-color: #{$identifier};
  --name-builtin-color: #{$statement};
  --name-class-color: #{$text-color};
  --name-class-font-weight: normal;
  --name-constant-color: #{$constant};
  --name-entity-color: #{$identifier};
  --name-exception-color: #{$identifier};
  --name-function-color: #{$identifier};
  --name-function-font-weight: normal;
  --name-namespace-color: #{$identifier};
  --name-tag-color: #{$identifier};
  --name-variable-color: #{$identifier};
  --text-whitespace-color: #{$text-color};
  --literal-number-float-color: #{$constant};
  --literal-number-hex-color: #{$constant};
  --literal-number-integer-color: #{$constant};
  --literal-number-oct-color: #{$constant};
  --literal-string-backtick-color: #{$constant};
  --literal-string-char-color: #{$constant};
  --literal-string-doc-color: #{$constant};
  --literal-string-double-color: #{$constant};
  --literal-string-escape-color: #{$constant};
  --literal-string-heredoc-color: #{$constant};
  --literal-string-interpol-color: #{$constant};
  --literal-string-other-color: #{$constant};
  --literal-string-regex-color: #{$constant};
  --literal-string-single-color: #{$constant};
  --literal-string-symbol-color: #{$constant};
  --name-builtin-pseudo-color: #{$special};
  --name-variable-class-color: #{$identifier};
  --name-variable-global-color: #{$identifier};
  --name-variable-instance-color: #{$identifier};
}
